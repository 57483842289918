import { common } from './config.common'

export const config = {
  ...common,
  environment: 'dev',
  baseUrl: 'http://localdev.mediuutiset.media:3002',
  apiRoot: 'https://qa.mediuutiset.media/api/',
  assetUrl: 'https://qa.mediuutiset.media',
  cookieDomain: '.mediuutiset.media',
  contentRouterUrl:
    'https://s3-eu-west-1.amazonaws.com/test-content-router-data/',
  imageServiceUrl: 'https://images.qa.almatalent.fi',
  changePasswordUrl: 'https://tunnus.qa.almatalent.fi/unohtunut-salasana/',
}
